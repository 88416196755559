import { useCallback, useState } from "react";
import axios from "axios";
import { useAuth } from "./useAuth";
import { handleError } from "../lib/utils";
import { useLoading } from "./LoadingContext";
import * as Sentry from "@sentry/browser";

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const useApiPost = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { startLoading, stopLoading } = useLoading();
  const { logout } = useAuth();

  const handlePost = useCallback(async (api, payload) => {
    setLoading(true);
    startLoading();
    try {
      const res = await axios.post(api, payload, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (!res.status === 200 || res.data.success === false) {
        setError({
          ...res.data,
          message: res.data.message || "something when wrong!!",
        });
      }

      setData(res.data);
      setError(null);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout();
      }
      Sentry.captureException(error, {
        extra: {
          api, // Capture the API endpoint
          payload, // Capture the payload that caused the error
          response: error.response ? error.response.data : null, // Capture response data if available
          status: error.response ? error.response.status : null, // Capture status code
        },
      });
      setError(handleError(error));
    }
    setLoading(false);
    stopLoading();
  }, []);

  const handlePostToken = useCallback(async (api, payload) => {
    setLoading(true);
    try {
      const res = await axios.post(api, payload, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });

      if (!res.status === 200 || res.data.success === false) {
        setError({
          ...res.data,
          message: res.data.message || "something when wrong!!",
        });
        Sentry.captureException(error, {
          extra: {
            api, // Capture the API endpoint
            payload, // Capture the payload that caused the error
            response: error.response ? error.response.data : null, // Capture response data if available
            status: error.response ? error.response.status : null, // Capture status code
          },
        });
      }

      setData(res.data);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        logout();
      }
      Sentry.captureException(error, {
        extra: {
          api, // Capture the API endpoint
          payload, // Capture the payload that caused the error
          response: error.response ? error.response.data : null, // Capture response data if available
          status: error.response ? error.response.status : null, // Capture status code
        },
      });
      setError({ message: error, data: null, success: false });
    }
    setLoading(false);
  }, []);

  return {
    handlePost,
    handlePostToken,
    data,
    loading,
    error,
  };
};
