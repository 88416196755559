import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { useAuth } from "../../hooks/useAuth";
import CakraInput from "../../components/CakraInput";

import { ACCOUNTING, ADMIN, TA } from "../../lib/constant";
import { useFetchData } from "../../hooks/useFetchData";
import { dateFormat } from "../../lib/utils";
import LabelWrapper from "../../components/ui/LabelWrapper";

const API_URL_GET_TA = {
  helpdesk: "/api/helpdesk-travel-agents",
  sales: "/api/travel-agents",
  accounting: "/api/helpdesk-travel-agents",
  admin: "/api/helpdesk-travel-agents",
};

const CHECKBOX_OPTION = [
  { value: "sales", label: "Sales" },
  { value: "travelAgent", label: "Travel Agent" },
  { value: "fronOffice", label: "Front office" },
];

const FilterDrawer = ({ handleSubmitFilter, genParams }) => {
  const [date, setDate] = useState({
    startDate: moment(),
    endDate: moment(),
  });
  const [selectedTa, setSelectedTa] = useState(null);
  const [selectedSales, setSelectedSales] = useState(null);
  const [selectedHelpdesk, setSelectedHelpdesk] = useState(null);
  const [taDataOptions, setTaDataOptions] = useState([]);
  const [salesDataOptions, setSalesDataOptions] = useState([]);
  const [helpdeskDataOptions, setHelpdeskDataOptions] = useState([]);
  const [checkBoxOption, setCheckBoxOption] = useState(
    genParams?.checkBoxOption || []
  );

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();

  const {
    loading: taLoading,
    data: taData,
    error: taError,
  } = useFetchData(API_URL_GET_TA[user.role] || "");

  const {
    loading: salesLoading,
    data: salesData,
    error: salesError,
  } = useFetchData(user?.role !== TA ? "/api/sales" : "");

  const {
    loading: helpdeskLoading,
    data: helpdeskData,
    error: helpdeskError,
  } = useFetchData(user?.role !== TA ? "/api/helpdesk" : "");

  useEffect(() => {
    if (user?.role !== TA) {
      if (!taLoading && taData && !taError) {
        let options = [];
        taData.data.map((item) => {
          options.push({
            value: item.travel_agent_id,
            label: `${item.pic_name} - ${item.name}`,
          });
        });
        setTaDataOptions(options);
      }
    }
  }, [taLoading, taData]);

  useEffect(() => {
    if (!salesLoading && salesData && !salesError) {
      let options = [];
      salesData.data.map((item) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      setSalesDataOptions(options);
    }
  }, [salesLoading, salesData]);

  useEffect(() => {
    if (!helpdeskLoading && helpdeskData && !helpdeskError) {
      let options = [];
      helpdeskData.data.map((item) => {
        options.push({
          value: item.id,
          label: item.name,
        });
      });
      setHelpdeskDataOptions(options);
    }
  }, [helpdeskLoading, helpdeskData]);

  const loadOptionsTa = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter(
        (item) =>
          item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          item.pic_name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: `${item.pic_name} - ${item.name}`,
        value: item.travel_agent_id,
      }));
    callback(data);
  };

  const loadOptionsSales = (inputValue, callback) => {
    if (!taData) return [];
    const data = taData.data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    callback(data);
  };

  const loadOptionsHelpdesk = (inputValue, callback) => {
    if (!helpdeskData) return [];
    const data = helpdeskData.data
      .filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));
    callback(data);
  };

  const handleFilterTaChange = (filterTa) => {
    const generateParams = {
      travel_agent_id: filterTa ? filterTa.value : "",
      ...(selectedSales && { sales_id: selectedSales.value }),
      start_date: dateFormat(date.startDate),
      end_date: dateFormat(date.endDate),
    };
    setSelectedTa(filterTa);
  };

  const handleFilterSalesChange = (filterSales) => {
    setSelectedSales(filterSales);
  };

  const handleFilterHelpdeskChange = (filterHelpdesk) => {
    setSelectedHelpdesk(filterHelpdesk);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      setCheckBoxOption((prev) => [...new Set([...prev, value])]);
    } else {
      setCheckBoxOption((prev) => prev.filter((item) => item !== value));
    }
  };

  return (
    <>
      <Button variant="green-outline" onClick={onOpen}>
        Filter
      </Button>
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Filter</DrawerHeader>
          <DrawerBody className="flex flex-col gap-4">
            <LabelWrapper className="flex flex-col">
              <Text className="text-gray-700 font-bold">
                Transaksi Berdasarkan
              </Text>
              {user?.role !== TA ? (
                <CakraInput>
                  <AsyncSelect
                    isClearable
                    defaultOptions={taDataOptions}
                    cacheOptions
                    loadOptions={loadOptionsTa}
                    onChange={handleFilterTaChange}
                    placeholder="travel agent"
                    value={selectedTa}
                    chakraStyles={{
                      input: (prev, { selectProps }) => ({
                        ...prev,
                        boxShadow: "none !important",
                      }),
                    }}
                  />
                </CakraInput>
              ) : null}
              {user?.role === ACCOUNTING || user?.role === ADMIN ? (
                <CakraInput>
                  <AsyncSelect
                    isClearable
                    defaultOptions={salesDataOptions}
                    cacheOptions
                    value={selectedSales}
                    loadOptions={loadOptionsSales}
                    onChange={handleFilterSalesChange}
                    placeholder="filter by sales"
                    chakraStyles={{
                      input: (prev, { selectProps }) => ({
                        ...prev,
                        boxShadow: "none !important",
                      }),
                    }}
                  />
                </CakraInput>
              ) : null}
            </LabelWrapper>
            <LabelWrapper className="flex flex-col">
              <Text className="text-gray-700 font-bold">Check in oleh</Text>
              {user?.role === ACCOUNTING || user?.role === ADMIN ? (
                <CakraInput>
                  <AsyncSelect
                    isClearable
                    defaultOptions={helpdeskDataOptions}
                    cacheOptions
                    defaultValue={selectedHelpdesk}
                    loadOptions={loadOptionsHelpdesk}
                    onChange={handleFilterHelpdeskChange}
                    placeholder="filter by front office"
                    chakraStyles={{
                      input: (prev, { selectProps }) => ({
                        ...prev,
                        boxShadow: "none !important",
                      }),
                    }}
                  />
                </CakraInput>
              ) : null}
            </LabelWrapper>
            <ButtonGroup w={"100%"}>
              <Button
                flex={1}
                onClick={() => {
                  onClose();
                }}
              >
                Batalkan
              </Button>
              <Button
                flex={1}
                colorScheme="green"
                onClick={() => {
                  handleSubmitFilter({
                    selectedTa,
                    selectedSales,
                    selectedHelpdesk,
                  });
                  onClose();
                }}
              >
                Submit
              </Button>
            </ButtonGroup>
          </DrawerBody>
        </DrawerContent>
        <DrawerFooter>Konto</DrawerFooter>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
