import React from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  Heading,
  LinkBox,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ChevronRight } from "lucide-react";
import { numberToIDR } from "../lib/utils";
import LoadingOverlay from "../components/ui/LoadingOverlay";

const PaymentTypeForm = ({
  handleDepositPayment,
  handleOnlinePayment,
  subtitle,
  onHandleBack,
  isDepositPayment = true,
  saldoDeposit,
}) => {
  return (
    <>
      <div className="py-4">
        <LoadingOverlay />
        <h2>Pilih Metode Pembayaran</h2>
        {/* <p>{subtitle || "Bantu travel agent untuk transaksi tiket"}</p> */}
      </div>
      <Stack spacing={5}>
        <LinkBox
          as="button"
          className="text-left border border-green-600 rounded-md"
          onClick={handleOnlinePayment}
        >
          <Card>
            <CardBody className="flex flex-row items-center">
              <Box className="w-full">
                <Heading size="md">Pembayaran online</Heading>
                <Text pt="2" fontSize="sm">
                  Bisa bayar dengan Va, Bank Transfer, Gopay, Spay
                </Text>
              </Box>
              <ChevronRight />
            </CardBody>
          </Card>
        </LinkBox>
        <LinkBox
          as="button"
          className="text-left border border-green-600 disabled:border-gray-400 rounded-md"
          disabled={!isDepositPayment}
          onClick={handleDepositPayment}
        >
          <Card
            disabled={!isDepositPayment}
            style={{
              cursor: !isDepositPayment ? "not-allowed" : "pointer",
              opacity: !isDepositPayment ? 0.6 : 1,
              boxShadow: !isDepositPayment ? "none" : "md",
            }}
          >
            <CardBody className="flex flex-row items-center">
              <Box className="w-full">
                {/* <Heading size="md"> */}
                <Stack direction="col" justifyContent="space-between" pr={4}>
                  <Heading size="md">Deposit</Heading>
                  <Heading size="md">
                    {`Saldo: ${numberToIDR(saldoDeposit)}`}
                  </Heading>
                </Stack>
                {/* </Heading> */}
                <Text pt="2" fontSize="sm">
                  Bisa bayar dengan Va, Bank Transfer, Gopay, Spay
                </Text>
              </Box>
              <ChevronRight />
            </CardBody>
          </Card>
        </LinkBox>
        {/* <Button
          colorScheme="bg-green-600"
          className="bg-green-600"
          onClick={handleOnlinePayment}
        >
          Online Payment
        </Button> */}
      </Stack>
      <Button
        leftIcon={<ArrowLeftIcon />}
        border={0}
        backgroundColor={"transparent"}
        textColor={"black"}
        _hover={{ opacity: "70%" }}
        mt={"1.25rem"}
        pl={0}
        onClick={() => onHandleBack()}
      >
        Ubah data pesanan
      </Button>
    </>
  );
};

export default PaymentTypeForm;
