import { clsx } from "clsx";
import moment from "moment";
import { twMerge } from "tailwind-merge";
import { isNumber } from "lodash";
import { Badge } from "@chakra-ui/react";
import { SKU_PREMIUM } from "./constant";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function numberToRupiah(value) {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
  }).format(value);
}

export function numberToIDR(value) {
  const formatted = new Intl.NumberFormat("id-ID", {
    style: "decimal",
    currency: "IDR",
    currencyDisplay: "code",
    maximumFractionDigits: 0,
  }).format(value);
  return `${formatted} IDR`;
}

export function numberWithoutIDR(value) {
  const formatted = new Intl.NumberFormat("id-ID", {
    style: "decimal",
    currency: "IDR",
    currencyDisplay: "code",
    maximumFractionDigits: 0,
  }).format(value);
  return `${formatted}`;
}

export function dateFormat(value) {
  if (isNumber(value) && value) {
    return moment.unix(value).format("DD MMM YYYY");
  }
  if (!isNumber(value) && value) return moment(value).format("DD MMM YYYY");
  return "-";
}

export function dateUnix(value) {
  return moment(value).unix();
}

export function dateTimeFormat(value) {
  if (isNumber(value)) {
    return moment.unix(value).format("DD MMM YYY, HH:mm");
  }
  return moment(value).format("DD MMM YYYY, HH:mm");
}

export function timeFormat(value) {
  return moment.unix(value).format("HH:mm");
}

export function timeFormatMillisec(value) {
  return moment(value, "HH:mm").format("HH:mm:ss");
}

export function handleError(error) {
  switch (error?.response?.status) {
    case 500:
      return {
        message: error.response.data.message || error.message,
        data: null,
        success: false,
      };
    case 401:
      return {
        message: error.response.data.message || error.message,
        data: null,
        success: false,
      };
    case 400:
      return {
        message: error.response.data.message || error.message,
        data: error.response.data.data || null,
        success: false,
      };
    case 403:
      return {
        message: error.response.status,
        data: null,
        success: false,
      };
    default:
      return null;
  }
}

export function renderUsedTotalTicket(ticketType, data) {
  let used = null;
  switch (ticketType) {
    case "vip":
      used = data.status_checkin.available_vip_ticket;
      break;
    case "regular":
      used = data.status_checkin.available_regular_ticket;
      break;
    default:
      used = 0;
      break;
  }

  const usedTicket = Math.abs(data.tickets[ticketType] - used);
  return `${ticketType === "vip" ? SKU_PREMIUM : ticketType} : ${usedTicket}`;
}

export const defineCheckInStatus = (status) => {
  let colorStyle = {
    COMPLETED: "green",
    FAILED: "default",
  };

  if (status && status.check_in === 0) {
    return (
      <Badge
        width="fit-content"
        fontWeight={"medium"}
        fontSize={"small"}
        variant={"subtle"}
        colorScheme={colorStyle["FAILED"]}
      >
        {`Belum check in`}
      </Badge>
    );
  }

  if (status && status.check_in > 0) {
    return (
      <Badge
        width="fit-content"
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle["COMPLETED"]}
      >
        {`Check in: ${status.check_in}`}
      </Badge>
    );
  }

  return (
    <Badge
      width="fit-content"
      fontStyle={"Inter"}
      fontSize={"sm"}
      fontWeight={"medium"}
      variant={"subtle"}
      colorScheme={"red"}
    >
      Terjadi Kesalahan
    </Badge>
  );
};

// sales

// const defineCheckInStatus = (status) => {
//   let colorStyle = {
//     COMPLETED: "green",
//     FAILED: "red",
//   };

//   if (status && status.check_in === 0) {
//     return (
//       <Badge
//         fontWeight={"medium"}
//         fontSize={"small"}
//         variant={"subtle"}
//         colorScheme={colorStyle["FAILED"]}
//       >
//         {`Belum check in`}
//       </Badge>
//     );
//   }

//   if (status && status.check_in > 0) {
//     return (
//       <Badge
//         fontWeight={"medium"}
//         fontSize={"sm"}
//         variant={"subtle"}
//         colorScheme={colorStyle["COMPLETED"]}
//       >
//         {`Check in: ${status.check_in}`}
//       </Badge>
//     );
//   }

//   return (
//     <Badge
//       fontStyle={"Inter"}
//       fontSize={"sm"}
//       fontWeight={"medium"}
//       variant={"subtle"}
//       colorScheme={"red"}
//     >
//       Terjadi Kesalahan
//     </Badge>
//   );
// };

export const defineTaStatus = (status) => {
  let colorStyle = {
    ACTIVE: "green",
    DEACTIVE: "red",
    DORMANT: "blue",
  };

  if (status) {
    return (
      <Badge
        fontWeight={"medium"}
        fontSize={"small"}
        variant={"subtle"}
        rounded={"md"}
        colorScheme={colorStyle[status]}
      >
        {status}
      </Badge>
    );
  }

  return (
    <Badge
      fontStyle={"Inter"}
      fontSize={"sm"}
      fontWeight={"medium"}
      variant={"subtle"}
      colorScheme={"red"}
    >
      Terjadi Kesalahan
    </Badge>
  );
};

export const defineRateCardStatus = (status) => {
  let colorStyle = {
    ACTIVE: "green",
    DEACTIVE: "red",
    WAITINGAPPROVAL: "blue",
  };

  if (status) {
    return (
      <Badge
        fontWeight={"medium"}
        fontSize={"small"}
        variant={"subtle"}
        rounded={"md"}
        colorScheme={colorStyle[status]}
      >
        {status}
      </Badge>
    );
  }

  return (
    <Badge
      fontStyle={"Inter"}
      fontSize={"sm"}
      fontWeight={"medium"}
      variant={"subtle"}
      colorScheme={"red"}
    >
      Terjadi Kesalahan
    </Badge>
  );
};

// travelAgent

export const definePaymentStatus = (status) => {
  let stat = {
    INITIATED: "Menunggu Pembayaran",
    COMPLETED: "Selesai",
    PENDING: "Pembayaran Terverifikasi",
    FAILED: "Dibatalkan",
    REFUNDED: "Selesai dengan Refund",
    ERROR: "Terjadi kesalahan",
  };
  let colorStyle = {
    INITIATED: "purple",
    COMPLETED: "green",
    PENDING: "blue",
    FAILED: "red",
    REFUNDED: "Default",
    ERROR: "red",
  };
  return (
    (
      <Badge
        // fontStyle={"inter"}
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const defineStatus = (status) => {
  let stat = {
    WAITING_FOR_PAYMENT: "Menunggu Pembayaran",
    CANCELLED: "Dibatalkan",
    PAYMENT_VERIFIED: "Pembayaran Terverifikasi",
    FINISHED: "Selesai",
    REFUNDED: "Selesai dengan Refund",
    ERROR: "Terjadi kesalahan",
  };

  let colorStyle = {
    WAITING_FOR_PAYMENT: "purple",
    FINISHED: "green",
    PAYMENT_VERIFIED: "blue",
    CANCELLED: "red",
    REFUNDED: "Default",
    ERROR: "red",
  };

  return (
    (
      <Badge
        width="fit-content"
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        width="fit-content"
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const defineActivityStatus = (status) => {
  let stat = {
    WAITING_FOR_APPROVAL: "menunggu persetujuan",
    APPROVED: "di setujui",
    REJECTED: "ditolak",
    INACTIVE: "tidak aktif",
  };

  let colorStyle = {
    WAITING_FOR_APPROVAL: "purple",
    APPROVED: "green",
    REJECTED: "red",
    INACTIVE: "Default",
  };

  return (
    (
      <Badge
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const defineDepositStatus = (status) => {
  let stat = {
    PENDING: "menunggu persetujuan",
    APPROVED: "diterima",
    REJECTED: "ditolak",
  };

  let colorStyle = {
    PENDING: "purple",
    APPROVED: "green",
    REJECTED: "red",
  };

  return (
    (
      <Badge
        // fontStyle={"inter"}
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const defineNoteStatus = (status) => {
  let stat = {
    OPEN: "Open",
    CLOSED: "Close",
  };

  let colorStyle = {
    OPEN: "red",
    CLOSED: "default",
  };

  return (
    (
      <Badge
        fontStyle={"inter"}
        fontWeight={"medium"}
        fontSize={"sm"}
        variant={"subtle"}
        colorScheme={colorStyle[status]}
      >
        {stat[status]}
      </Badge>
    ) || (
      <Badge
        fontStyle={"Inter"}
        fontSize={"sm"}
        fontWeight={"medium"}
        variant={"subtle"}
        colorScheme={"red"}
      >
        Terjadi Kesalahan
      </Badge>
    )
  );
};

export const truncateString = (str) => {
  return str.substring(0, 18);
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.split(",")[1] || "");
    reader.onerror = (error) => reject(error);
  });
};

export const capitalizeWords = (str) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase());
};
