// src/routes/navLinksConfig.js
import { CheckCheck, CircleUserRoundIcon, Home, Search } from "lucide-react";
import {
  IcAirplaneTicketOutline,
  IcBaselineArrowBack,
  IcOutlineFactCheck,
  IcOutlineFileCopy,
  IcOutlineSetting,
} from "../assets/icons/icon";

export const navLinksConfig = {
  helpdesk: [
    {
      name: "Beranda",
      icon: Home,
      link: "/helpdesk/dashboard",
    },
    {
      name: "Pembelian Tiket",
      icon: IcOutlineFactCheck,
      link: "/helpdesk/pembelian-tiket",
    },
    {
      name: "Scan Check in",
      icon: Search,
      link: "/helpdesk/scanQr",
    },
    {
      name: "Reservasi Check",
      icon: CheckCheck,
      link: "/helpdesk/reservation-check",
    },
    {
      name: "Laporan",
      icon: IcOutlineFileCopy,
      link: "/helpdesk/laporan",
    },
    {
      name: "Pengaturan",
      icon: IcOutlineSetting,
      link: "/helpdesk/pengaturan",
    },
    {
      name: "Keluar",
      icon: IcBaselineArrowBack,
      link: "/",
    },
  ],
  travelAgent: [
    {
      name: "Transaksi Pembelian",
      icon: IcOutlineFactCheck,
      link: "/travel-agent/pembelian-tiket",
    },
    {
      name: "Partnership",
      icon: IcAirplaneTicketOutline,
      link: "/travel-agent/partnership",
    },
    {
      name: "Laporan",
      icon: IcOutlineFileCopy,
      link: "/travel-agent/laporan",
    },
    {
      name: "Pengaturan",
      icon: IcOutlineSetting,
      link: "/travel-agent/pengaturan",
    },
    {
      name: "Keluar",
      icon: IcBaselineArrowBack,
      link: "/",
    },
    // Add more travel agent routes here
  ],
  sales: [
    {
      name: "Beranda",
      icon: Home,
      link: "/sales/dashboard",
    },
    {
      name: "Travel Agent",
      icon: IcAirplaneTicketOutline,
      link: "/sales/travel-agent",
    },
    {
      name: "Transaksi Pembelian",
      icon: IcOutlineFactCheck,
      link: "/sales/pembelian-tiket",
    },
    // {
    //   name: "Rate Code",
    //   icon: IcDollar,
    //   link: "/sales/rate-code",
    // },
    {
      name: "Laporan",
      icon: IcOutlineFileCopy,
      link: "/sales/laporan",
    },
    {
      name: "Pengaturan",
      icon: IcOutlineSetting,
      link: "/sales/pengaturan",
    },
    {
      name: "Keluar",
      icon: IcBaselineArrowBack,
      link: "/",
    },
    // Add more TL routes here
  ],
  accounting: [
    {
      name: "Laporan",
      icon: IcOutlineFileCopy,
      link: "/accounting/laporan",
    },
    {
      name: "Pengaturan",
      icon: IcOutlineSetting,
      link: "/accounting/pengaturan",
    },
    {
      name: "Keluar",
      icon: IcBaselineArrowBack,
      link: "/",
    },
    // Add more TL routes here
  ],
  admin: [
    {
      name: "Master Pegawai",
      icon: CircleUserRoundIcon,
      link: "/admin/master-account",
    },
    {
      name: "Master Approver",
      icon: IcOutlineFactCheck,
      link: "/admin/master-approver",
    },
    {
      name: "Laporan",
      icon: IcOutlineFileCopy,
      link: "/admin/laporan",
    },
    // {
    //   name: "Master Harga",
    //   icon: IcDollar,
    //   link: "/admin/master-ticket-price",
    // },
    {
      name: "Keluar",
      icon: IcBaselineArrowBack,
      link: "/",
    },
    // Add more TL routes here
  ],

  // Add more roles and their respective routes here
};
